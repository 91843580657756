.player-controls {
  display: flex;
  align-items: center;
  flex-basis: 100px;
  justify-content: space-between;
}

.player-controls button {
  cursor: pointer;
  background: none;
  border: none;
}

.player-controls svg {
  fill: white;
  width: 100%;
  height: 100%;
}

.skip-button {
  padding: 8px;
  width: 40px;
  height: 40px;
}

.play-pause-button {
  padding: 4px;
  width: 40px;
  height: 40px;
}
