.project {
  display:flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: solid 1px #ffffff1a;
  font-weight: 400;
}

.project-item {
  display: flex;
  align-items: center;
}

.project-item button {
  cursor: pointer;
  background:none;
  border: none;
  width: 32px;
  height: 32px;
}

.project-play-button {
  padding:5px;
}

.project-item svg {
  fill: white;
  width: 100%;
  height: 100%;
}

.project-item a {
  color: white;
}

.project-link-button {
  padding: 8px;
}

.project-number {
  flex-basis: 50px;
}

.project-name {
  margin-left: 10px;
  flex-grow: 4;
  cursor: pointer;
}

.project:hover {
  background-color: #ffffff1a;
}

.selected {
  background-color: #ffffff1a;
  font-weight: 500;
}

.project-description {
  margin: 10px 20px;
  font-weight: normal;
}

.desc{
  transition-duration: 100ms;
  transition-property: transform;
  transition-timing-function: linear;
  padding: 4px;
}

.desc.closed{
  transform: rotate(90deg);
}
