.education {
  margin-bottom: 40px;
}

.grad-icon {
  display: flex;
  align-items: center;
  width: 45px;
  height: 45px;
}
.grad-icon > * {
  font-size: 35px;
}

.capm-badge {
  width: 45px;
  height: 45px;
}

.degree {
  display:flex;
  align-items: center;
  margin-bottom: 10px;
}

.degree-details {
  margin-left: 20px;
}

.degree-name {
  font-weight: bold;
}

.course {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 5px;
}

.course img {
  height: 90%;
  width: 90%;
  padding-bottom: 5px;
}

.course svg {
  fill: black;
  width: 100%;
  height: 100%
}

.coursework h2 {
  font-size: 22px;
  font-weight: 400;
}