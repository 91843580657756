.popup {
  /* animation-name: show;
  animation-duration: 200ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards; */

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  height: calc(100% - 54px);
  width: 100vw;
  background-color: black;
  z-index: 8;

  transition-duration: 500ms;
  transition-property: transform;
  transition-timing-function: linear;
  /* transform-origin: 0 100%; */
  transform: translate3d(0, 0, 0);
}

.popup.minimized {
  transform: translate3d(0, 100%, 0);
  /* visibility: hidden; set a few seconds after */
}

.video-container {
  visibility: visible;
}

/* @keyframes show {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */

.miniplayer {
  position: fixed;
  top: -300px; 
  right: 50px;
  width: 300px;
  height: 300px;
  visibility: hidden;
}

.preview-left {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.preview-right {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: 50%;
}

.preview-tabs {
  display: flex;
  width: 100%;
  height: 48px;
  border-bottom: solid 1px #ffffff1a;
}

.tab {
  display: flex;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tab.open {
  border-bottom: solid 1px #ffffff;
}

.tab-content {
  overflow-y: auto;
}

.popup video {
  height: 100%;
  width: 100%;
}

.exit-preview {
  position: absolute;
  top: 55px;
  width: 100%;
  height: 63%;
  text-align: right;
}

.exit-preview .exit-button {
  margin: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: inherit;
  color: white;
  visibility: hidden;
}

.exit-preview:hover {
  background-color: #8d8b8b69;
}

.exit-preview:hover .exit-button{
  visibility: visible;
}

@media only screen and (min-width: 768px) {
  .miniplayer {
    visibility: visible;
  }
}

@media only screen and (min-width: 1024px) {
  .popup {
    flex-direction: row;
  }
  .preview-left {
    height: 100%;
    flex-basis: 50%;
  }
  .preview-right {
    height: 100%;
    flex-basis: 50%;
  }
}
