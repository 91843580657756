.footer {
  position: fixed;
  bottom: 0;
  background-color: #3e3e3e;
  width: 100vw;
  z-index: 10;
}

.preview-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.left-controls {
  display: flex;
  align-items: center;
  flex: 1;
}

.project-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
}

.right-controls {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.caret {
  cursor: pointer;
  background: none;
  border: none;
  transition: transform 200ms linear;
  padding: 4px;
  width: 40px;
  height: 40px;
}

.caret.up{
  transform: rotate(180deg);
}

.caret svg {
  width: 100%;
  height: 100%;
  fill: white;
}