.carousel-item {
  display: flex;
  flex-direction: column;
  flex-basis: 150px;
  flex-shrink: 0;
  align-items: center;
}

.icon-container {
  display: flex;
  height: 150px;
  width: 150px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.icon-container.square {
  border-radius: 4px;
  border: solid 1px white;
}

.icon-container.round {
  border-radius: 50%;
  border: solid 1px transparent;

}

.carousel-item-description {
  padding: 10px 0;
  text-align: center;
}