.app {
  padding-bottom: 80px;
}

.page-padding {
  padding-bottom: 120px;
}

.content {
  padding: 0 30px;
}

@media only screen and (min-width: 540px) {
  #aboutme {
    display: none;
  }
  .content {
    padding: 0 50px;
  }
}