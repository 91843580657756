.about {
  position: relative;
  height: 270px;
}

.profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-background {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg,#030303 7%,#00000000 100%);
  z-index: 1;
}

.about-content-container {
  width: 100%;
}

.about-content {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  padding-bottom: 20px;
}

.about-me {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80%;
  font-weight: 400;
}

.about-me-link {
  text-decoration: none;
  color: inherit;
}

.about-me-link:visited {
  color: inherit;
}

.icon-strip {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  width: 100%;
}

.icon {
  font-size: 32px;
}

.github {
  fill: white;
  height: 32px;
  width: 32px;
}

@media only screen and (min-width: 540px) {
  .about {
    height: 370px;
  }
  .about-content {
    margin: 0 50px;
  }
  .about-me {
    overflow: visible;
    white-space: pre-wrap;
  }
  .about-me-link {
    display: none;
  }
  .icon-strip {
    width: 300px;
  }
}

@media only screen and (min-width: 1024px) {
  .about {
    height: 450px;
  }
}

@media only screen and (min-width: 1440px) {
  .about {
    height: 550px;
  }
}

@media only screen and (min-width: 1580px) {
  .about {
    height: 650px;
  }
}